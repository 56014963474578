// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-developers-js": () => import("./../../../src/pages/developers.js" /* webpackChunkName: "component---src-pages-developers-js" */),
  "component---src-pages-developers-rococo-network-js": () => import("./../../../src/pages/developers/rococo-network.js" /* webpackChunkName: "component---src-pages-developers-rococo-network-js" */),
  "component---src-pages-developers-smart-contracts-js": () => import("./../../../src/pages/developers/smart-contracts.js" /* webpackChunkName: "component---src-pages-developers-smart-contracts-js" */),
  "component---src-pages-developers-substrate-connect-js": () => import("./../../../src/pages/developers/substrate-connect.js" /* webpackChunkName: "component---src-pages-developers-substrate-connect-js" */),
  "component---src-pages-ecosystem-connect-contact-js": () => import("./../../../src/pages/ecosystem/connect/contact.js" /* webpackChunkName: "component---src-pages-ecosystem-connect-contact-js" */),
  "component---src-pages-ecosystem-js": () => import("./../../../src/pages/ecosystem.js" /* webpackChunkName: "component---src-pages-ecosystem-js" */),
  "component---src-pages-ecosystem-opportunities-grants-js": () => import("./../../../src/pages/ecosystem/opportunities/grants.js" /* webpackChunkName: "component---src-pages-ecosystem-opportunities-grants-js" */),
  "component---src-pages-ecosystem-opportunities-hackathons-js": () => import("./../../../src/pages/ecosystem/opportunities/hackathons.js" /* webpackChunkName: "component---src-pages-ecosystem-opportunities-hackathons-js" */),
  "component---src-pages-ecosystem-projects-js": () => import("./../../../src/pages/ecosystem/projects.js" /* webpackChunkName: "component---src-pages-ecosystem-projects-js" */),
  "component---src-pages-ecosystem-resources-awesome-substrate-js": () => import("./../../../src/pages/ecosystem/resources/awesome-substrate.js" /* webpackChunkName: "component---src-pages-ecosystem-resources-awesome-substrate-js" */),
  "component---src-pages-ecosystem-resources-past-seminars-js": () => import("./../../../src/pages/ecosystem/resources/past-seminars.js" /* webpackChunkName: "component---src-pages-ecosystem-resources-past-seminars-js" */),
  "component---src-pages-ecosystem-resources-seminar-js": () => import("./../../../src/pages/ecosystem/resources/seminar.js" /* webpackChunkName: "component---src-pages-ecosystem-resources-seminar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-technology-flexible-js": () => import("./../../../src/pages/technology/flexible.js" /* webpackChunkName: "component---src-pages-technology-flexible-js" */),
  "component---src-pages-technology-future-proof-js": () => import("./../../../src/pages/technology/future-proof.js" /* webpackChunkName: "component---src-pages-technology-future-proof-js" */),
  "component---src-pages-technology-interoperable-js": () => import("./../../../src/pages/technology/interoperable.js" /* webpackChunkName: "component---src-pages-technology-interoperable-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-technology-open-js": () => import("./../../../src/pages/technology/open.js" /* webpackChunkName: "component---src-pages-technology-open-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-vision-substrate-and-polkadot-js": () => import("./../../../src/pages/vision/substrate-and-polkadot.js" /* webpackChunkName: "component---src-pages-vision-substrate-and-polkadot-js" */),
  "component---src-templates-single-project-js": () => import("./../../../src/templates/single-project.js" /* webpackChunkName: "component---src-templates-single-project-js" */)
}

